@font-face {
  font-family: 'Gotham-light';
  src: url('../assets/fonts/gotham/Gotham-Light.otf');
}

@font-face {
  font-family: 'Gotham-medium';
  src: url('../assets/fonts/gotham/Gotham-Medium.otf');
}

@font-face {
  font-family: 'Gotham-bold';
  src: url('../assets/fonts/gotham/Gotham-Bold.otf');
}
