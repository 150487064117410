// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #F3B6A3;
  --ion-color-primary-rgb: 243, 182, 163;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #d6a08f;
  --ion-color-primary-tint: #f4bdac;

  /** secondary **/
  --ion-color-secondary: #A1EAF4;
  --ion-color-secondary-rgb: 161, 234, 244;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #8eced7;
  --ion-color-secondary-tint: #aaecf5;

  /** tertiary **/
  --ion-color-tertiary: #FFC2C0;
  --ion-color-tertiary-rgb: 255, 194, 192;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #e0aba9;
  --ion-color-tertiary-tint: #ffc8c6;

  /** success **/
  --ion-color-success: #AEF2BA;
  --ion-color-success-rgb: 174, 242, 186;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #99d5a4;
  --ion-color-success-tint: #b6f3c1;

  /** warning **/
  --ion-color-warning: #FCEBC7;
  --ion-color-warning-rgb: 252, 235, 199;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #decfaf;
  --ion-color-warning-tint: #fcedcd;

  /** danger **/
  --ion-color-danger: #F89693;
  --ion-color-danger-rgb: 248, 150, 147;
  --ion-color-danger-contrast: #000000;
  --ion-color-danger-contrast-rgb: 0, 0, 0;
  --ion-color-danger-shade: #da8481;
  --ion-color-danger-tint: #f9a19e;


  /** dark **/
  --ion-color-dark: #C6C6C6;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** transparent **/
  --ion-color-transparent: #C6C6C600;
  --ion-color-transparent-rgb: 34, 36, 40;
  --ion-color-transparent-contrast: #ffffff;
  --ion-color-transparent-contrast-rgb: 255, 255, 255;
  --ion-color-transparent-shade: #1e2023;
  --ion-color-transparent-tint: #383a3e;

  /** gradient **/
  --ion-color-gradient: linear-gradient(99deg, rgba(243, 182, 163, 1) 0%, rgba(161, 234, 244, 1) 100%);
  --ion-color-gradient-rgb: 209, 0, 255;
  --ion-color-gradient-contrast: var(--ion-color-sd-black);
  --ion-color-gradient-contrast-rgb: 255, 255, 255;
  --ion-color-gradient-shade: linear-gradient(99deg, rgba(243, 182, 163, 1) 0%, rgba(161, 234, 244, 1) 100%);
  --ion-color-gradient-tint: #383a3e;

  /** gradient-mbl **/
  --ion-color-gradient-mbl: linear-gradient(0deg, rgb(245, 180, 159) 0%, rgb(159, 235, 246) 100%);
  --ion-color-gradient-mbl-rgb: 209, 0, 255;
  --ion-color-gradient-mbl-contrast: var(--ion-color-sd-black);
  --ion-color-gradient-mbl-contrast-rgb: 255, 255, 255;
  --ion-color-gradient-mbl-shade: #1e2023;
  --ion-color-gradient-mbl-tint: #383a3e;

  /** gradient-go-now **/
  --ion-color-gradient-go-now: linear-gradient(180deg, #F3B6A3 0%, #FFA4A3 100%);
  --ion-color-gradient-go-now-rgb: 209, 0, 255;
  --ion-color-gradient-go-now-contrast: var(--ion-color-sd-black);
  --ion-color-gradient-go-now-contrast-rgb: 255, 255, 255;
  --ion-color-gradient-go-now-shade: #1e2023;
  --ion-color-gradient-go-now-tint: #383a3e;

  /** gradient-primary **/
  --ion-color-gradient-primary: linear-gradient(0deg, #FFD3CC 0%, #F3B6A3 100%);
  --ion-color-gradient-primary-rgb: 209, 0, 255;
  --ion-color-gradient-primary-contrast: var(--ion-color-sd-black);
  --ion-color-gradient-primary-contrast-rgb: 255, 255, 255;
  --ion-color-gradient-primary-shade: #1e2023;
  --ion-color-gradient-primary-tint: #383a3e;

  /** gradient-primary **/
  --ion-color-gradient-primary-reverse: linear-gradient(0deg, #F3B6A3 0%, #FFD3CC 100%);
  --ion-color-gradient-primary-reverse-rgb: 209, 0, 255;
  --ion-color-gradient-primary-reverse-contrast: var(--ion-color-sd-black);
  --ion-color-gradient-primary-reverse-contrast-rgb: 255, 255, 255;
  --ion-color-gradient-primary-reverse-shade: #1e2023;
  --ion-color-gradient-primary-reverse-tint: #383a3e;

  /** gradient-green **/
  --ion-color-gradient-green: linear-gradient(0deg, #C2E8AC 0%, #DEF8FB 100%);
  --ion-color-gradient-green-rgb: 209, 0, 255;
  --ion-color-gradient-green-contrast: var(--ion-color-sd-black);
  --ion-color-gradient-green-contrast-rgb: 255, 255, 255;
  --ion-color-gradient-green-shade: #1e2023;
  --ion-color-gradient-green-tint: #383a3e;

  /** gradient-blue **/
  --ion-color-gradient-blue: linear-gradient(0deg, #A1EAF4 0%, #DEF8FB 100%);
  --ion-color-gradient-blue-rgb: 209, 0, 255;
  --ion-color-gradient-blue-contrast: var(--ion-color-sd-black);
  --ion-color-gradient-blue-contrast-rgb: 255, 255, 255;
  --ion-color-gradient-blue-shade: #1e2023;
  --ion-color-gradient-blue-tint: #383a3e;

  /** gradient-purple **/
  --ion-color-gradient-purple: linear-gradient(0deg, #DEF8FB 0%, #E2CAF6 100%);
  --ion-color-gradient-purple-rgb: 209, 0, 255;
  --ion-color-gradient-purple-contrast: var(--ion-color-sd-black);
  --ion-color-gradient-purple-contrast-rgb: 255, 255, 255;
  --ion-color-gradient-purple-shade: #1e2023;
  --ion-color-gradient-purple-tint: #383a3e;

  /** gradient-beige **/
  --ion-color-gradient-beige: linear-gradient(0deg, #FAF0E6 0%, #DEF8FB 100%);
  --ion-color-gradient-beige-rgb: 209, 0, 255;
  --ion-color-gradient-beige-contrast: var(--ion-color-sd-black);
  --ion-color-gradient-beige-contrast-rgb: 255, 255, 255;
  --ion-color-gradient-beige-shade: #1e2023;
  --ion-color-gradient-beige-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #F1EFF0;
  --ion-color-medium-rgb: 198, 198, 198;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #aeaeae;
  --ion-color-medium-tint: #cccccc;

  /** light **/
  --ion-color-light: #FAFAFA;
  --ion-color-light-rgb: 250, 250, 250;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #dcdcdc;
  --ion-color-light-tint: #fbfbfb;

  /** light2 **/
  --ion-color-light2: #f4f4f4;
  --ion-color-light2-rgb: 250, 250, 250;
  --ion-color-light2-contrast: #000000;
  --ion-color-light2-contrast-rgb: 0, 0, 0;
  --ion-color-light2-shade: #dcdcdc;
  --ion-color-light2-tint: #fbfbfb;

  /** black **/
  --ion-color-sd-black: #1A1919;
  --ion-color-sd-black-rgb: 26, 25, 25;
  --ion-color-sd-black-contrast: #ffffff;
  --ion-color-sd-black-contrast-rgb: 255, 255, 255;
  --ion-color-sd-black-shade: #171616;
  --ion-color-sd-black-tint: #313030;

  /** white **/
  --ion-color-sd-white: #FFFFFF;
  --ion-color-sd-white-rgb: 255, 255, 255;
  --ion-color-sd-white-contrast: #000000;
  --ion-color-sd-white-contrast-rgb: 0, 0, 0;
  --ion-color-sd-white-shade: #e0e0e0;
  --ion-color-sd-white-tint: #ffffff;

  /** sd-green **/
  --ion-color-sd-green: #C2E8AC;
  --ion-color-sd-green-rgb: 194, 232, 172;
  --ion-color-sd-green-contrast: #000000;
  --ion-color-sd-green-contrast-rgb: 0, 0, 0;
  --ion-color-sd-green-shade: #abcc97;
  --ion-color-sd-green-tint: #c8eab4;

  /** sd-red **/
  --ion-color-sd-red: #F89693;
  --ion-color-sd-red-rgb: 248, 150, 147;
  --ion-color-sd-red-contrast: #000000;
  --ion-color-sd-red-contrast-rgb: 0, 0, 0;
  --ion-color-sd-red-shade: #da8481;
  --ion-color-sd-red-tint: #f9a19e;

  /** sd-error **/
  --ion-color-sd-error: #ff0707;
  --ion-color-sd-error-rgb: 255, 7, 7;
  --ion-color-sd-error-contrast: #000000;
  --ion-color-sd-error-contrast-rgb: 0, 0, 0;
  --ion-color-sd-error-shade: #ff0707;
  --ion-color-sd-error-tint: #ff0707;

  /** sd-yellow **/
  --ion-color-sd-yellow: #FCEBC7;
  --ion-color-sd-yellow-rgb: 252, 235, 199;
  --ion-color-sd-yellow-contrast: #000000;
  --ion-color-sd-yellow-contrast-rgb: 0, 0, 0;
  --ion-color-sd-yellow-shade: #decfaf;
  --ion-color-sd-yellow-tint: #fcedcd;

  /** sd-purple **/
  --ion-color-sd-purple: #DECCF3;
  --ion-color-sd-purple-rgb: 222, 204, 243;
  --ion-color-sd-purple-contrast: #000000;
  --ion-color-sd-purple-contrast-rgb: 0, 0, 0;
  --ion-color-sd-purple-shade: #c3b4d6;
  --ion-color-sd-purple-tint: #e1d1f4;

  /** sd-blue **/
  --ion-color-sd-blue: #DEF8FB;
  --ion-color-sd-blue-rgb: 222, 248, 251;
  --ion-color-sd-blue-contrast: #000000;
  --ion-color-sd-blue-contrast-rgb: 0, 0, 0;
  --ion-color-sd-blue-shade: #c3dadd;
  --ion-color-sd-blue-tint: #e1f9fb;

  /** sd-marine **/
  --ion-color-sd-marine: #B3D5DD;
  --ion-color-sd-marine-rgb: 179, 213, 221;
  --ion-color-sd-marine-contrast: #000000;
  --ion-color-sd-marine-contrast-rgb: 0, 0, 0;
  --ion-color-sd-marine-shade: #9ebbc2;
  --ion-color-sd-marine-tint: #bbd9e0;

  /** sd-beige **/
  --ion-color-sd-beige: #FAF0E6;
  --ion-color-sd-beige-rgb: 250, 240, 230;
  --ion-color-sd-beige-contrast: #000000;
  --ion-color-sd-beige-contrast-rgb: 0, 0, 0;
  --ion-color-sd-beige-shade: #dcd3ca;
  --ion-color-sd-beige-tint: #fbf2e9;

  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 255, 255, 255;

  --ion-text-color: #1A1919;
  --ion-text-color-rgb: 26, 25, 25;

  --ion-color-step-50: #f4f4f4;
  --ion-color-step-100: #e8e8e8;
  --ion-color-step-150: #dddddd;
  --ion-color-step-200: #d1d1d1;
  --ion-color-step-250: #c6c6c6;
  --ion-color-step-300: #bababa;
  --ion-color-step-350: #afafaf;
  --ion-color-step-400: #a3a3a3;
  --ion-color-step-450: #989898;
  --ion-color-step-500: #8d8c8c;
  --ion-color-step-550: #818181;
  --ion-color-step-600: #767575;
  --ion-color-step-650: #6a6a6a;
  --ion-color-step-700: #5f5e5e;
  --ion-color-step-750: #535353;
  --ion-color-step-800: #484747;
  --ion-color-step-850: #3c3c3c;
  --ion-color-step-900: #313030;
  --ion-color-step-950: #252525;

  --ion-font-family: 'Gotham-light', sans-serif;

  // MBL VARIABLES
  --mbl-radius: 30px;
  --ion-input-error-color: #FF0707;
  --ion-safe-area-left: 0px;
  --ion-safe-area-right: 0px;
  --ion-safe-area-bottom: 30px;
}

.ion-color-black {
  --ion-color-base: var(--ion-color-sd-black);
  --ion-color-base-rgb: var(--ion-color-sd-black-rgb);
  --ion-color-contrast: var(--ion-color-sd-black-contrast);
  --ion-color-contrast-rgb: var(--ion-color-sd-black-contrast-rgb);
  --ion-color-shade: var(--ion-color-sd-black-shade);
  --ion-color-tint: var(--ion-color-sd-black-tint);
}

.ion-color-white {
  --ion-color-base: var(--ion-color-sd-white);
  --ion-color-base-rgb: var(--ion-color-sd-white-rgb);
  --ion-color-contrast: var(--ion-color-sd-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-sd-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-sd-white-shade);
  --ion-color-tint: var(--ion-color-sd-white-tint);
}

.ion-color-transparent {
  --ion-color-base: var(--ion-color-transparent);
  --ion-color-base-rgb: var(--ion-color-transparent-rgb);
  --ion-color-contrast: var(--ion-color-transparent-contrast);
  --ion-color-contrast-rgb: var(--ion-color-transparent-contrast-rgb);
  --ion-color-shade: var(--ion-color-transparent-shade);
  --ion-color-tint: var(--ion-color-transparent-tint);
}

.ion-color-green {
  --ion-color-base: var(--ion-color-sd-green);
  --ion-color-base-rgb: var(--ion-color-sd-green-rgb);
  --ion-color-contrast: var(--ion-color-sd-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-sd-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-sd-green-shade);
  --ion-color-tint: var(--ion-color-sd-green-tint);
}

.ion-color-red {
  --ion-color-base: var(--ion-color-sd-red);
  --ion-color-base-rgb: var(--ion-color-sd-red-rgb);
  --ion-color-contrast: var(--ion-color-sd-red-contrast);
  --ion-color-contrast-rgb: var(--ion-color-sd-red-contrast-rgb);
  --ion-color-shade: var(--ion-color-sd-red-shade);
  --ion-color-tint: var(--ion-color-sd-red-tint);
}

.ion-color-yellow {
  --ion-color-base: var(--ion-color-sd-yellow);
  --ion-color-base-rgb: var(--ion-color-sd-yellow-rgb);
  --ion-color-contrast: var(--ion-color-sd-yellow-contrast);
  --ion-color-contrast-rgb: var(--ion-color-sd-yellow-contrast-rgb);
  --ion-color-shade: var(--ion-color-sd-yellow-shade);
  --ion-color-tint: var(--ion-color-sd-yellow-tint);
}

.ion-color-purple {
  --ion-color-base: var(--ion-color-sd-purple);
  --ion-color-base-rgb: var(--ion-color-sd-purple-rgb);
  --ion-color-contrast: var(--ion-color-sd-purple-contrast);
  --ion-color-contrast-rgb: var(--ion-color-sd-purple-contrast-rgb);
  --ion-color-shade: var(--ion-color-sd-purple-shade);
  --ion-color-tint: var(--ion-color-sd-purple-tint);
}

.ion-color-blue {
  --ion-color-base: var(--ion-color-sd-blue);
  --ion-color-base-rgb: var(--ion-color-sd-blue-rgb);
  --ion-color-contrast: var(--ion-color-sd-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-sd-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-sd-blue-shade);
  --ion-color-tint: var(--ion-color-sd-blue-tint);
}

.ion-color-marine {
  --ion-color-base: var(--ion-color-sd-marine);
  --ion-color-base-rgb: var(--ion-color-sd-marine-rgb);
  --ion-color-contrast: var(--ion-color-sd-marine-contrast);
  --ion-color-contrast-rgb: var(--ion-color-sd-marine-contrast-rgb);
  --ion-color-shade: var(--ion-color-sd-marine-shade);
  --ion-color-tint: var(--ion-color-sd-marine-tint);
}

.ion-color-beige {
  --ion-color-base: var(--ion-color-sd-beige);
  --ion-color-base-rgb: var(--ion-color-sd-beige-rgb);
  --ion-color-contrast: var(--ion-color-sd-beige-contrast);
  --ion-color-contrast-rgb: var(--ion-color-sd-beige-contrast-rgb);
  --ion-color-shade: var(--ion-color-sd-beige-shade);
  --ion-color-tint: var(--ion-color-sd-beige-tint);
}

.ion-color-gradient {
  --ion-color-base: var(--ion-color-gradient);
  --ion-color-base-rgb: var(--ion-color-gradient-rgb);
  --ion-color-contrast: var(--ion-color-gradient-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gradient-contrast-rgb);
  --ion-color-shade: var(--ion-color-gradient-shade);
  --ion-color-tint: var(--ion-color-gradient-tint);
}

.ion-color-gradient-mbl {
  --ion-color-base: var(--ion-color-gradient-mbl);
  --ion-color-base-rgb: var(--ion-color-gradient-mbl-rgb);
  --ion-color-contrast: var(--ion-color-gradient-mbl-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gradient-mbl-contrast-rgb);
  --ion-color-shade: var(--ion-color-gradient-mbl-shade);
  --ion-color-tint: var(--ion-color-gradient-mbl-tint);
}

.ion-color-gradient-go-now {
  --ion-color-base: var(--ion-color-gradient-go-now);
  --ion-color-base-rgb: var(--ion-color-gradient-go-now-rgb);
  --ion-color-contrast: var(--ion-color-gradient-go-now-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gradient-go-now-contrast-rgb);
  --ion-color-shade: var(--ion-color-gradient-go-now-shade);
  --ion-color-tint: var(--ion-color-gradient-go-now-tint);
}

.ion-color-gradient-primary {
  --ion-color-base: var(--ion-color-gradient-primary);
  --ion-color-base-rgb: var(--ion-color-gradient-primary-rgb);
  --ion-color-contrast: var(--ion-color-gradient-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gradient-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-gradient-primary-shade);
  --ion-color-tint: var(--ion-color-gradient-primary-tint);
}

.ion-color-gradient-green {
  --ion-color-base: var(--ion-color-gradient-green);
  --ion-color-base-rgb: var(--ion-color-gradient-green-rgb);
  --ion-color-contrast: var(--ion-color-gradient-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gradient-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-gradient-green-shade);
  --ion-color-tint: var(--ion-color-gradient-green-tint);
}

.ion-color-gradient-blue {
  --ion-color-base: var(--ion-color-gradient-blue);
  --ion-color-base-rgb: var(--ion-color-gradient-blue-rgb);
  --ion-color-contrast: var(--ion-color-gradient-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gradient-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-gradient-blue-shade);
  --ion-color-tint: var(--ion-color-gradient-blue-tint);
}

.ion-color-gradient-purple {
  --ion-color-base: var(--ion-color-gradient-purple);
  --ion-color-base-rgb: var(--ion-color-gradient-purple-rgb);
  --ion-color-contrast: var(--ion-color-gradient-purple-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gradient-purple-contrast-rgb);
  --ion-color-shade: var(--ion-color-gradient-purple-shade);
  --ion-color-tint: var(--ion-color-gradient-purple-tint);
}

.ion-color-gradient-beige {
  --ion-color-base: var(--ion-color-gradient-beige);
  --ion-color-base-rgb: var(--ion-color-gradient-beige-rgb);
  --ion-color-contrast: var(--ion-color-gradient-beige-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gradient-beige-contrast-rgb);
  --ion-color-shade: var(--ion-color-gradient-beige-shade);
  --ion-color-tint: var(--ion-color-gradient-beige-tint);
}

.ion-color-error {
  --ion-color-base: var(--ion-input-error-color);
  --ion-color-base-rgb: var(--ion-color-error-rgb);
  --ion-color-contrast: var(--ion-color-error-contrast);
  --ion-color-contrast-rgb: var(--ion-color-error-contrast-rgb);
  --ion-color-shade: var(--ion-color-error-shade);
  --ion-color-tint: var(--ion-color-error-tint);
}
