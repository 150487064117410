ion-icon {
  font-size: 24px;
  will-change: transform;
}

ion-icon.small {
  font-size: 16px;
}

ion-icon.extra-small {
  font-size: 12px;
}
