.modal {
  --max-height: 80%;
  --overflow: visible;
  --border-radius: 0;
  --background: transparent;
  align-items: flex-end;
  overflow: initial;

  &.full {
    --max-height: 100%;
  }

  .modal-wrapper {
    background: transparent;
  }

  &__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    --background: transparent;
    --padding-top: 15px;
    position: relative;
  }

  .ion-page::before {
    position: absolute;
    content: "";
    height: 30px;
    width: calc(100% - 30px);
    top: -15px;
    left: 15px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    background: rgba(255, 255, 255, 0.4);
  }
}

#background-content {
  --border-radius: var(--mbl-radius) var(--mbl-radius) 0 0;
}

ion-modal.auto-height {
  &.bottom {
    align-items: flex-end;
  }

  --height: auto;
  --max-height: 90%;

  &.full {
    --max-height: 100%;
  }

  .ion-page {
    position: relative;
    display: block;
    // contain: content;

    .modal__container {
      overflow: auto;
    }
  }
}

ion-modal.setup-modal {
  --height: 90%;
  --max-height: 90%;
}
