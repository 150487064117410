h1 {
  font-family: 'Gotham-bold', sans-serif;
  font-size: 30px;
  letter-spacing: -.5px;
  text-transform: uppercase;
  margin: 5px 0;
  z-index: 2;

  &.form {
    letter-spacing: -.6px;
    text-transform: none;
    width: fit-content;
    position: relative;

    &::before {
      position: absolute;
      left: -15px;
      top: 25px;
      content: '';
      display: block;
      width: 100%;
      height: 15px;
      background-color: var(--ion-color-sd-blue);
      z-index: -1;
    }
  }
}

h2 {
  font-family: 'Gotham-medium', sans-serif;
  font-size: 20px;
  letter-spacing: -.5px;
  margin: 5px 0;
}

h3 {
  font-family: 'Gotham-bold', sans-serif;
  font-size: 20px;
  letter-spacing: -.5px;
  margin: 5px 0;
}

h4 {
  font-family: 'Gotham-bold', sans-serif;
  font-size: 14px;
  letter-spacing: -.28px;
  margin: 5px 0;
}

.graduate {
  font-family: 'Gotham-medium', sans-serif;
  font-size: 16px;
  letter-spacing: -.32px;
}

.subtitle {
  font-family: 'Gotham-light', sans-serif;
  font-size: 14px;
  letter-spacing: -.32px;
  margin-top: 5px;
  margin-bottom: 15px;

  strong {
    font-family: 'Gotham-bold', sans-serif;
    font-weight: unset;
  }
}

.light {
  font-family: 'Gotham-light', sans-serif;
}

p {
  font-family: 'Gotham-medium', sans-serif;
  font-size: 12px;
  letter-spacing: -.28px;
  margin: 5px 0;
  line-height: 18px;

  strong {
    font-family: 'Gotham-bold', sans-serif;
    font-weight: unset;
  }
}

.subtitle-medium {
  font-family: 'Gotham-medium', sans-serif;
  font-size: 14px;
  letter-spacing: -.32px;
  margin-top: 5px;
  margin-bottom: 15px;
}

.text {
  font-family: 'Gotham-medium', sans-serif;
  font-size: 10px;
  letter-spacing: -.2px;
  margin: 5px 0 15px;
  line-height: 10px;
}

.description {
  font-family: 'Gotham-light', sans-serif;
  font-size: 10px;
  letter-spacing: -.2px;
  line-height: 10px;
  margin: auto;
}

.showcase-title {
  display: block;
  font-family: 'Gotham-medium', sans-serif;
  font-size: 20px;
  letter-spacing: -.5px;
  text-transform: capitalize;
  margin: 5px 0;
  color: var(--ion-color-sd-white);

  &::first-line {
    font-family: 'Gotham-bold', sans-serif;
    font-size: 35px;
    letter-spacing: -.5px;
    text-transform: uppercase;
    margin: 5px 0;
    z-index: 2;
  }
}

span.header-title {
  font-family: 'Gotham-bold', sans-serif;
  font-size: 20px;
  letter-spacing: -.4px;
}

h1.fancy-title {
  display: block;
  text-transform: none;
  position: relative;
  span {
      position: relative;
      z-index: 1;
  }
  &::before {
      content: "";
      position: absolute;
      z-index: 0;
      background: var(--ion-color-sd-blue);
      width: 100%;
      height: 15px;
      bottom: 0;
      left: -15px;
  }
}