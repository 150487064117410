/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* CSS theming MBL */
@import "./theme/fonts.scss";
@import "./theme/icons.scss";
@import "./theme/texts.scss";
@import "./theme/modal.scss";

/* CSS FOR MAGIC PLAYER */
/*@import 'vidstack/styles/defaults.css';*/
@import 'vidstack/player/styles/base.css';


// use to have the navtab with border-radius on content
.tabs-inner {
  position: unset !important;
  contain: unset !important;
}


:host .picker-item {
  color: black !important;
  font-weight: bold !important;
}

.ion-color-light2 {
  --ion-color-base: var(--ion-color-light2);
  --ion-color-base-rgb: var(--ion-color-light2-rgb);
  --ion-color-contrast: var(--ion-color-light2-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light2-contrast-rgb);
  --ion-color-shade: var(--ion-color-light2-shade);
  --ion-color-tint: var(--ion-color-light2-tint);
}


